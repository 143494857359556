import React, { Component } from 'react';
import { Paper, Typography, TextField, Button, Avatar} from '@material-ui/core';
import { IUserContext } from './../Context';
import api from '../../../api/api';
import config from './../../../api/config';
import Toast, { wait } from './../../elements/Toast';
import { manager } from './../../../App';
import logo from './../../styles/title.png';
import { Link } from 'react-router-dom';
import validateEmail from './emailValidator';

const styles = {
    root: {
        width: '90%',
        maxWidth: '440px',
        alignSelf: 'center',
        padding: '15pt',
        boxShadow: 'none',
        border: 'none',
        background: 'none'
    },
    button: {
        border: 'none',
        background: '#54f2f2',
        height: '54px',
        borderRadius: '26px',
        fontSize: '16px',
        color: 'white',
        marginBottom: '10px',
        marginTop: '10px'
    },
    addButtonLabel: {
        textAlign: 'center',
        position: "absolute",
        /* bottom: 0; */
        alignSelf: 'flex-end',
        right: 'calc((100% - 147px) / 2)',
        margin: 'auto'
    },
    alreadyHave:{
        fontSize:'14px',
        fontFamily:'Lato',
        color: 'white',
        fontWeight:300,
        textAlign: "center" as "center",
        textTransform: 'uppercase' as "uppercase",
        letterSpacing: '0.5px',
        marginBottom: '70px'
    },
    input: {
        borderRadius: '14px'
    }
}


export default class LoginPage extends Component<any, { form: any, done: boolean, errors: string[], show: boolean, text: string }> {
    constructor(props: { cxt: IUserContext }) {
        super(props);
        this.state = {
            form: {
                //email: this.props.cxt && this.props.cxt.user && this.props.cxt.user.email,
                avatar: '',
                password: '',
                email: this.props.cxt.user.email
            },
            done: false,
            errors: [],
            show: false,
            text: ''
        }
    }
    public static propTypes = {};

    componentDidMount() {
        manager.toggle(false);
    }

    handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            this.submit();
        }
    }
    setToast = async (show: boolean, text?: string, time?: number) => {
        this.setState({ show, text: text || this.state.text });
        if (show && time) {
            await wait(time);
            this.setState({ show: false });
        }
    }
    handleClose = (event: React.SyntheticEvent | React.MouseEvent, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }

        this.setToast(false);
    }
    handleFileChange = (e: any) => {

        // get the files
        let files = e.target.files;

        // Process each file
        var allFiles: any[] = [];
        for (var i = 0; i < files.length; i++) {

            let file = files[i];

            // Make new FileReader
            let reader = new FileReader();

            // Convert the file to base64 text
            reader.readAsDataURL(file);

            // on reader load somthing...
            reader.onload = () => {

                // Make a fileInfo Object
                const size = Math.ceil(file.size / 1000);
                let fileInfo = {
                    name: file.name,
                    type: file.type,
                    size: size + ' kB',
                    base64: reader.result,
                    file: file,
                };
                if (size > 50) {
                    return this.setToast(true, 'Avatar must be at max 50kb!', 3500)
                }

                // Push it to the state
                allFiles.push(fileInfo);

                // If all files have been proceed
                if (allFiles.length === files.length && allFiles[0].type.substr(0, 5) === "image") {
                    // Apply Callback function
                    //console.log(allFiles[0]);
                    const form = this.state.form;
                    form.avatar = allFiles[0].base64;
                    this.setState({ form })
                }

            } // reader.onload

        } // for

    }

    handleChange = (e: any) => {
        const { form } = this.state;
        form[e.target.name] = e.target.value;
        this.setState({ form });
    }

    submit = () => {
        const { form } = this.state;
        console.log(form);
        if(validateEmail && !validateEmail(form.email)){
            return this.setToast(true, 'This email is invalid!', 2500)
        }
        if ((form.password.length > 0 || this.props.cxt.user.steamid || this.props.cxt.user.steamid || this.props.cxt.user.googleid) || form.avatar.length > 0 || form.email.length > 0) {
            api.user.update(this.props.cxt.user.id, { password: form.password, avatar: form.avatar, email: form.email }, async () => {
                //this.setState({show:true, text:'Saved!'})
                this.setToast(true, 'Saved!', 2500)
                //await wait(2500);
                //this.setToast(false);
            }, async (e: any) => {
                console.log(e);
                //this.setState({show:true, text:'There has been an error!'})
                this.setToast(true, 'There has been an error!', 2500)
                //await wait(2500);
                //this.setToast(false);
            })
        }
    }
    deleteAccount = () => {
        api.user.delete(this.props.cxt.user.id, () => {
            this.props.cxt.logout();
        })
    }

    render() {
        return (
            <Paper style={styles.root}>
                <Link to='/' className="logo-container"><img src={logo} className='logo-login'/></Link>
                <Typography variant="h5" component="h1" className="interactive-map-title avatar-site not-low">
                Watch Dogs: Legion Interactive Map
                    <Typography variant="h6" component="div" className="beta-container">Beta</Typography>
                </Typography>
                <div style={styles.alreadyHave}>
                    <Link to='/login' className="login-link">Go back home</Link>
                </div>
                <div className="form-container">
                    <input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        className="input-file"
                        onChange={this.handleFileChange}
                    />
                    <div className='avatar-container'>
                        <Avatar alt="Remy Sharp" src={this.state.form.avatar || `${config.apiAddress}avatar/${this.props.cxt.user.id}`} className='avatar-profile' />

                        <label htmlFor="contained-button-file" style={{
                            textAlign: 'center',
                            position: "absolute",
                             bottom: 0,
                            alignSelf: 'flex-end',
                            right: 'calc((100% - 147px) / 2)',
                            margin: 'auto',
                            cursor: 'pointer'
                        }}>
                            <div className="add-button">
                                +
                        </div>
                        </label>
                    </div>



                    <TextField
                        label="Email"
                        name='email'
                        value={this.state.form.email}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                        type='email'
                        onKeyPress={this.handleKeyPress}
                        inputProps={{
                            style: {
                                color: 'white'
                            }
                        }}
                        InputProps={{
                            classes: {
                                notchedOutline: 'input-login'
                            }
                        }}
                        InputLabelProps={{
                            classes: {
                                root: 'input-login-label'
                            }
                        }}
                    />
                    {!this.props.cxt.user.steamid && !this.props.cxt.user.facebookid && !this.props.cxt.user.googleid ? <TextField
                        label="Reset your password"
                        name='password'
                        value={this.state.form.password}
                        onChange={this.handleChange}
                        margin="normal"
                        variant="outlined"
                        type='password'
                        onKeyPress={this.handleKeyPress}
                        inputProps={{
                            style: {
                                color: 'white'
                            }
                        }}
                        InputProps={{
                            classes: {
                                notchedOutline: 'input-login'
                            }
                        }}
                        InputLabelProps={{
                            classes: {
                                root: 'input-login-label'
                            }
                        }}
                    /> : ''}
                    <Button variant="outlined" onClick={this.submit} style={styles.button} >
                        Save
                    </Button>
                    <Toast text={this.state.text} setToast={this.setToast} show={this.state.show} />
                    <Button onClick={this.deleteAccount} style={styles.button} >
                        Remove Account
                    </Button>
                </div>
            </Paper>
        )
    }
}
