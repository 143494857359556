import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { UserContext, IUserContext, UserType } from './Context';
import ModsList from './Maps/Mods';
import LoginPage, { LogOut} from './Users/LoginPage';
import RegisterPage from './Users/RegisterPage';
import ProfilePage from './Users/ProfilePage';
/*import ModEditPage from './Mods/Mod';
import ModPage from './Mods/ModPage';
import UserPage from './Users/UserPage';
import ReportPage from './Mods/ReportPage';
import AdminPanel from './ReactAdmin/Panel';*/
import AdminPanel from './ReactAdmin/Panel';
import Preloader from './Preloader';
import ForgotPassword from './Users/ForgotPassword';
import RememberPassword from './Users/RememberPassword';

class EmptyContent extends React.Component<any, any> {
	render() {
		return (
			<div className='container'>
				<div className='row pagewide-message'>
					<Preloader/>
				</div>
			</div>
		)
	}
}

class SimpleRoute extends React.Component<any, {userContext: React.Context<IUserContext>}> {
	render(){
		return (
            <main className={`main-container ${this.props.mainRow ? 'row-main':''}`}>
				<UserContext.Consumer>
					{user => {
						const { protect, deprotect, hideHeader, ...rest } = this.props;
						if(!hideHeader !== user.showHeader){
							user.setHeader(!hideHeader)
						}
						if(protect){
							return <ProtectedRoute {...rest} />
						}else if(deprotect){
							return <DeprotectedRoute {...rest} />
						} else {
							return <Route {...rest} />
						}
					}}
				</UserContext.Consumer>
			</main>
		)
	}
}

class ProtectedRoute extends React.Component<any, {userContext: React.Context<IUserContext>}> {
    render(){
        return (
            <UserContext.Consumer>
                {user => {
					const {adminOnly, redirectTo, ...rest} = this.props;
					switch(user.type) {
						case UserType.Unloaded:
							return <EmptyContent/>;
						case UserType.Guest:
							return <Redirect to={redirectTo || '/'}/>;
						case UserType.User:
							if(adminOnly) return <Redirect to={redirectTo || '/'}/>;
							return <Route {...rest}/>;
						case UserType.Admin:
							return <Route {...rest}/>;
						default:
							console.log('This should never happen!');
							return <Redirect to={redirectTo || '/'}/>;
					}
                }}
            </UserContext.Consumer>
        )
    }
}

class DeprotectedRoute extends React.Component<any, any> {
    render(){
        return (
            <UserContext.Consumer>
                {user => {
                    const {adminOnly, redirectTo, ...rest} = this.props;
					switch(user.type) {
						case UserType.Unloaded:
							return <EmptyContent/>;
						case UserType.Guest:
							return <Route {...rest}/>;
						default:
							return <Redirect to={redirectTo || '/'}/>;
					}
                }}
            </UserContext.Consumer>
        )
    }
}
export default class Content extends React.Component {
    render() {
        return(
                <UserContext.Consumer>
                    {context => {
                        return <Switch>
							<SimpleRoute path='/' exact render={() => <ModsList cxt={context}/>}/>
							<SimpleRoute path='/:markerName/:marker([0-9]+)' exact render={(props: any) => <ModsList cxt={context} {...props}/>}/>
							<SimpleRoute protect path='/my' exact render={(props: any) => <ModsList hideUpdates hideCategories hideTags query={{creator: context.user ? context.user.id : undefined}} {...props}/>}/>
							<SimpleRoute protect path='/watched' exact render={(props: any) => <ModsList hideCategories hideTags query={{filter: 'watched'}} {...props}/>}/>
							<SimpleRoute protect adminOnly path='/admin/' hideHeader exact component={AdminPanel}/>
							<SimpleRoute deprotect mainRow path='/register' hideHeader exact render={() => <RegisterPage cxt={context}/>}/>
                            <SimpleRoute deprotect mainRow path="/login" hideHeader exact render={()=><LoginPage cxt={context} />}/>
							<SimpleRoute deprotect path='/reset' hideHeader exact component={ForgotPassword} />
							<SimpleRoute deprotect path='/remember/:token([0-9a-zA-Z]+)' hideHeader exact render={(props:any) => <RememberPassword {...props}/>} />
                            <SimpleRoute protect path="/logout" hideHeader adminOnly={false} exact render={()=><LogOut cxt={context} />}/>
							<SimpleRoute protect path="/profile" hideHeader exact render={() => < ProfilePage cxt={context}/>} />
                        </Switch>}
                    }
                </UserContext.Consumer>
        );
    }
}
/*
export default class Content extends React.Component {
    render() {
        return(
            <main>
                <UserContext.Consumer>
                    {context => {
                        return <Switch>
							<Route path='/' exact render={() => <ModsList cxt={context}/>}/>
							<ProtectedRoute path='/my' exact render={(props: any) => <ModsList hideUpdates hideCategories hideTags query={{creator: context.user ? context.user.id : undefined}} {...props}/>}/>
							<ProtectedRoute path='/watched' exact render={(props: any) => <ModsList hideCategories hideTags query={{filter: 'watched'}} {...props}/>}/>
							<ProtectedRoute adminOnly path='/admin' hideHeader exact component={AdminPanel}/>
							<DeprotectedRoute path='/register' exact render={() => <RegisterPage cxt={context}/>}/>
                            <DeprotectedRoute path="/login" exact render={()=><LoginPage cxt={context} />}/>
                            <ProtectedRoute path="/logout" adminOnly={false} exact render={()=><LogOut cxt={context} />}/>
							<ProtectedRoute path="/profile" exact render={() => < ProfilePage cxt={context}/>} />
							<Route path='/:slug([0-9a-zA-Z_-]+)' exact component={ModsList}/>
                        </Switch>}
                    }
                </UserContext.Consumer>
            </main>
        );
    }
}
*/
/*

							<ProtectedRoute adminOnly path='/admin' exact component={AdminPanel}/>
							<Route path='/report/:id([0-9]+)' exact component={ReportPage}/>
							<DeprotectedRoute path='/register' exact render={() => <RegisterPage cxt={context}/>}/>
                            <ProtectedRoute path='/edit/:id([0-9]+)' exact component={ModEditPage}/>
							<Route path='/mods/:id([0-9]+)' exact component={ModPage}/>
							<Route path='/user/:id([0-9]+)' exact component={UserPage}/>
							<ProtectedRoute path='/add' redirectTo='/register' exact component={ModEditPage}/>
							<ProtectedRoute path='/add/:addid([0-9]+)' redirectTo='/register' exact component={ModEditPage}/>
							*/
//<DeprotectedRoute path='/' exact render={() => <LoginPage context={context}/>}/>
//<ProtectedRoute path='/phases/:tId([0-9]+)' permission="tournaments" exact render={(props: any) => <Phases {...props}/>}/>
