import { IconButton, Tooltip } from "@material-ui/core";
import api from "../../api/api";
import React from "react";
import AcceptButton from './../styles/accept_button.png'
import UnAcceptButton from './../styles/unaccept_button.png'

export default class ToggleFoundButton extends React.Component<{ isFound: boolean, markerId: number, callback: any }, { isFound: boolean, markerId: number }>{
    constructor(props: { isFound: boolean, markerId: number, callback: any }) {
        super(props);
        this.state = this.props;
    }
    componentDidUpdate() {
        if (this.state.isFound !== this.props.isFound || this.state.markerId !== this.props.markerId) {
            this.setState({ markerId: this.props.markerId, isFound: this.props.isFound });
        }
    }
    toggle = () => {
        if (this.state.isFound) {
            api.markers.removeFound(this.state.markerId, this.props.callback);
        } else {
            api.markers.setFound(this.state.markerId, this.props.callback);
        }
    }
    render() {
        if (this.state.markerId === 0) return '';
        const label = `Have you visited this location?`
        return <Tooltip title={label} aria-label={label}>
            <IconButton style={{ padding: '8px' }} onClick={this.toggle}>
                {!this.state.isFound ? <img src={AcceptButton} /> : <img src={UnAcceptButton} />}
            </IconButton>
        </Tooltip>
    }
}