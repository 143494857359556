import * as I from './../../api/interfaces';

interface Filter {
    showDLC?: boolean,
    categories: number[],
    found?: boolean
    //onChange
}

export default class MarkerFilter {
    markers: I.ExtendedMarker[];
    filter: Filter;
    categories: I.Category[];
    show: boolean;
    search: string;
    callbacks: (() => void)[];
    handleSearch: () => void;
    toggleCallback: () => void;
    map: any;

    constructor(){
        this.markers = [];
        this.map = null;
        this.filter = {
            categories: [],
            showDLC: true
        }
        this.show = false;
        this.callbacks = []
        this.categories = []
        this.handleSearch = () => {};
        this.search = '';
        this.toggleCallback = () => {};
    }

    toggle(state?: boolean){
        if(state !== undefined){
            this.show = state;
            return this.toggleCallback();
        }
        this.show = !this.show;
        return this.toggleCallback();
    }

    getFiltered(){
        let markers = this.markers;
        const { showDLC, categories, found } = this.filter;
        if(showDLC !== undefined){
            markers = markers.filter(marker => marker.isDLC === showDLC);
        }
        if(found !== undefined){
            markers = markers.filter(marker => marker.found === found);
        }

        return markers.filter(marker => categories.includes(marker.category));
    }

    updateFilter(key: 'showDLC' | 'categories' | 'found', value: any){
        this.filter[key] = value;
        this.exec();
    }
    exec(){
        this.callbacks.forEach(callback => {
            callback();
        });
    }
    setSearch(search: string){
        this.search = search;
        this.handleSearch();
    }
    onSearchChange(func: () => void){
        this.handleSearch = func;
    }
    onChange(func: () => void){
        this.callbacks.push(func);
    }
    setMarkers(markers: I.ExtendedMarker[]){
        this.markers = markers;
        this.exec();
    }
    setCategories(categories: I.Category[]){
        this.categories = categories;
        this.exec();
    }

}