import React, { Component } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ScrollToTop from './components/modules/Scroll';
import Header from './components/modules/Header';
import Content from './components/modules/Content';
import Footer from './components/modules/Footer';
import API from './api/api';

import {defaultContext, IUserContext, UserType as UT, UserContext} from './components/modules/Context';
import {IUser} from './components/modules/definitions';

import './components/styles/maincolors.css';
import './components/styles/index.css';
import MarkerFilter from './components/modules/ModsFilter';

export const manager = new MarkerFilter();
//import './components/styles/materialize.min.css';
//import './components/styles/index.css';

declare let window: any;

class App extends Component<any,{user:IUserContext}> {
	constructor(props: any){
		super(props);
		const AppContext = this;

		defaultContext.logout = function() {
			API.user.logout(() => {
				this.type = UT.Guest;
				this.user = undefined;
				AppContext.setState({user: defaultContext});
			});
		};

		defaultContext.loadUser = function(){
			API.user.getMyself((user: IUser) => {
				this.type = user.admin ? UT.Admin : UT.User;
				this.user = user;
			
				AppContext.setState({user: defaultContext});
			}, () => {
				this.type = UT.Guest;
				this.user = undefined;
				AppContext.setState({user: defaultContext});
			});
		}

		defaultContext.login = function(username:string, password: string, errorCallback?:any) {
			API.user.login({username, password}, ()=>{
				this.loadUser();
			}, errorCallback);
		}
		defaultContext.setHeader = function(show: boolean){
			this.showHeader = show;
			AppContext.setState({user: defaultContext});
		}

		defaultContext.updateContext = defaultContext.loadUser;

		this.state = {
			user: defaultContext
		}
	}

	componentDidMount(){
		this.state.user.loadUser();
	}

	render() {
		return (
			//<AdminPanel />
			<BrowserRouter>
				<ScrollToTop>
					<UserContext.Provider value={this.state.user}>
						{<Header context={this.state.user}/>}
						<Content />
						<Footer />
					</UserContext.Provider>
				</ScrollToTop>
			</BrowserRouter>
		);
	}
}

export default App;
