import React, { Component } from 'react';
import { Paper, Typography, TextField, Button } from '@material-ui/core';
import Toast, { wait } from './../../elements/Toast';
import { IUserContext } from './../Context';
import { Redirect, Link } from 'react-router-dom';
import { manager } from './../../../App';
import LoginButton from './../LoginButton';

import logo from './../../styles/title.png';


const styles = {
    root: {
        width: '90%',
        maxWidth: '440px',
        alignSelf: 'center',
        paddingTop: '15pt',
        paddingBottom: '15pt',
        boxShadow: 'none',
        border: 'none',
        background: 'none',
    },
    button: {
        border: 'none',
        background: 'var(--btn-special-background)',
        minHeight: '54px',
        borderRadius: '26px',
        fontSize: '16px',
        color: 'var(--btn-special-text)',
        fontFamily: 'Poppins',
        fontWeight:500
    },

    input: {
        borderRadius: '14px'
    },
    alreadyHave:{
        fontSize:'14px',
        fontFamily:'Lato',
        color: 'white',
        fontWeight:300,
        textAlign: "center" as "center",
        marginTop: '50px'
    },
    orMainContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '40px',
        marginBottom: '20px',
    },
    bar: {
        height: '1px',
        flex: 1,
        backgroundColor: 'rgba(255,255,255,0.5)'
    },
    or: {
        marginLeft: '47px',
        color: 'white',
        marginRight: '47px',
        fontSize: '18px',
        fontFamily: 'Lato'
    }
}
export default class LoginPage extends Component<any, { form: any, done: boolean, errors: string[], snackbar: boolean, text: string }> {
    constructor(props: { cxt: IUserContext }) {
        super(props);
        this.state = {
            form: {
                password: '',
                username: ''
            },
            done: false,
            errors: [],
            snackbar: false,
            text: ''
        }
    }


    handleKeyPress = (e: any) => {
        if (e.key === 'Enter') {
            this.login();
        }
    }

    handleChange = (e: any) => {
        const { form } = this.state;
        form[e.target.name] = e.target.value;
        this.setState({ form });
    }

    setToast = async (show: boolean, text?: string, time?: number) => {
        this.setState({ snackbar: show, text: text || this.state.text });
        if (show && time) {
            await wait(time);
            this.setState({ snackbar: false });
        }
    }

    login = () => {
        let errors: string[] = [];
        if (!this.state.form.password.length) errors.push("Password is required");
        if (!this.state.form.username.length) errors.push("Username is required");

        if (errors.length) {
            return this.setState({ errors });
        }
        if (!errors.length) return this.props.cxt.login(this.state.form.username, this.state.form.password, (res: any) => {
            if (res.status === 403) {
                /*this.setState({snackbar:true}, () => {
                    setTimeout(() => {
                        this.setState({snackbar:false});
                    }, 3500);
                })*/

                errors.push("Your account is banned");
                //window.M.toast({ html: 'Your account is banned', displayLength: 1500 })
            } else if (res.status === 401) {
                errors.push("Incorrect password");
                //this.setToast(true, 'Your username or password is incorrect', 3000);
                //this.setState({snackbar:true, text:'Your username or password is incorrect'})
            } else if (res.status === 404) {
                errors.push("User does not exist");
            } else if (Array.isArray(res.errors)) {
                //this.setState({snackbar:true, text:'There has been an error!'})
                this.setToast(true, 'There has been an error!', 3000);
                //window.M.toast({ html: 'There has been an error!', displayLength: 1500 })
            }
            return this.setState({ errors })
        });
    }
    componentDidMount() {
        manager.toggle(false);
    }

    render() {
        return (
            <div className="login-container">
                <div className="login-form-container">
                    <Paper style={styles.root}>
                        <Link to='/' className="logo-container"><img src={logo} className='logo-login' /></Link>
                        <Toast text={this.state.text} setToast={this.setToast} show={this.state.snackbar} />
                        <Typography variant="h5" component="h1" className="interactive-map-title">
                        Watch Dogs: Legion Interactive Map
                    <Typography variant="h6" component="div" className="beta-container">Beta</Typography>
                        </Typography>
                        {this.state.errors.map(error =>
                            <Typography variant="subtitle2" component="p" key={error} color="error">
                                {error}
                            </Typography>)}
                        <div className="form-container">
                            <TextField
                                label="Username"
                                name='username'
                                value={this.state.form.username}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                                style={styles.input}
                                inputProps={{
                                    style: {
                                        color: 'white'
                                    }
                                }}
                                InputProps={{
                                    classes: {
                                        notchedOutline: 'input-login'
                                    }
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: 'input-login-label'
                                    }
                                }}
                                onKeyPress={this.handleKeyPress}
                            />
                            <TextField
                                label="Password"
                                name='password'
                                value={this.state.form.password}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                                type='password'
                                inputProps={{
                                    style: {
                                        color: 'white'
                                    }
                                }}
                                InputProps={{
                                    classes: {
                                        notchedOutline: 'input-login'
                                    }
                                }}
                                InputLabelProps={{
                                    classes: {
                                        root: 'input-login-label'
                                    }
                                }}
                                onKeyPress={this.handleKeyPress}
                            />
                            <Link to={'/reset'} className="reset-link">Forgot your password?</Link>
                            <Button variant="outlined" onClick={this.login} style={styles.button} >
                                Log in
                            </Button>
                            <div style={styles.orMainContainer}>
                                <div style={styles.bar}></div>
                                <div style={styles.or}>or</div>
                                <div style={styles.bar}></div>
                            </div>
                            <LoginButton type="steam" />
                            <LoginButton type="google" />
                            <LoginButton type="battlenet" />
                            <div style={styles.alreadyHave}>
                            Don‘t have a profile? <Link to='/register' className="login-link">Register for FREE</Link>
                            </div>
                        </div>

                    </Paper>
                </div>
                <div className="login-side-background">

                </div>
            </div>
        )
    }
}

export class LogOut extends Component<{ cxt: IUserContext }> {
    componentDidMount() {
        this.props.cxt.logout();
    }
    render() {
        if (!this.props.cxt.user) return (<Redirect to='/' />);
        return (<div></div>);
    }
}

