import L from 'leaflet';

function generateIcon(hex?: string | null) {
	const svgString = `<svg id="Isolation_Mode" data-name="Isolation Mode" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 72.7 94.94">
		<path fill="${hex || '#fdd76d'}" d="M27.1,1.16C10.8,5.16,0,19.56,0,36.36a34.86,34.86,0,0,0,1.5,10.4,32.23,32.23,0,0,0,4.7,9.9c.5.8,18.5,25.5,26.5,36.4a4.59,4.59,0,0,0,7.4,0c8-10.9,26.1-35.7,26.6-36.6a33.08,33.08,0,0,0,4.5-9.7,35.83,35.83,0,0,0,1.5-10.4C72.8,13.16,51.2-4.84,27.1,1.16Z" /></svg>`
	
	const buff = Buffer.from(svgString);
	const b64 = buff.toString('base64');
	return `data:image/svg+xml;base64,${b64}`
}

export const blueIcon = new L.Icon({
	iconUrl: '/icons/default/marker-icon-2x-blue.png',
	shadowUrl: 'marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const redIcon = new L.Icon({
	iconUrl: '/icons/default/marker-icon-2x-red.png',
	//shadowUrl: 'marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const greenIcon = new L.Icon({
	iconUrl: '/icons/default/marker-icon-2x-green.png',
	shadowUrl: 'marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const orangeIcon = new L.Icon({
	iconUrl: '/icons/default/marker-icon-2x-orange.png',
	shadowUrl: 'marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const yellowIcon = new L.Icon({
	iconUrl: '/icons/default/marker-icon-2x-yellow.png',
	shadowUrl: 'marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const violetIcon = new L.Icon({
	iconUrl: '/icons/default/marker-icon-2x-violet.png',
	shadowUrl: 'marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const greyIcon = new L.Icon({
	iconUrl: '/icons/default/marker-icon-2x-grey.png',
	shadowUrl: 'marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const blackIcon = new L.Icon({
	iconUrl: '/icons/default/marker-icon-2x-black.png',
	shadowUrl: 'marker-shadow.png',
	iconSize: [25, 41],
	iconAnchor: [12, 41],
	popupAnchor: [1, -34],
	shadowSize: [41, 41]
});

export const customIcon = (url: string, shadowHex?: string | null, iconSize?: any) => new L.Icon({
	iconUrl: url,
	iconSize: [25, 25],
	iconAnchor: [13, 51],
	popupAnchor: [1, -34],
	className: 'custom-icon icon-bcg-color-' + shadowHex?.slice(1),
})