import React, { Component } from 'react';
import { Map, Marker, TileLayer, Tooltip as LeafTooltip } from 'react-leaflet';
import L from 'leaflet';
import * as Icons from './../../styles/markers/leaflet-color-markers';
import config from './../../../api/config';
import api from '../../../api/api';
import Fab from '@material-ui/core/Fab';
import Checkbox from '@material-ui/core/Checkbox';
import * as I from './../../../api/interfaces';
import Markers from './Marker';
import { manager } from './../../../App';

import { Add as AddIcon, Edit } from '@material-ui/icons'
import { Modal, Paper, Typography, TextField, Button, MenuItem, FormControl, FormLabel, Radio, RadioGroup, FormControlLabel, Switch, Snackbar } from '@material-ui/core';
import Alert from '../../elements/Alert';

const styless = {
    root: {
        width: '95%',
        maxWidth: '625px',
        alignSelf: 'center',
        paddingTop: '65px',
        paddingBottom: '71px',
        margin: 'auto',
        outline: "none",
        marginBottom: '100px',
        borderRadius: '30px'
    },
    button: {
        border: 'none',
        background: 'var(--btn-special-background)',
        minHeight: '54px',
        borderRadius: '26px',
        fontSize: '16px',
        color: 'white',
        fontFamily: 'Poppins',
        fontWeight: 500,
        marginTop: '50px'
    },
    buttonDisabled: {
        border: 'none',
        background: 'var(--btn-special-background)',
        minHeight: '54px',
        borderRadius: '26px',
        fontSize: '16px',
        opacity: 0.5,
        color: 'white',
        fontFamily: 'Poppins',
        fontWeight: 500,
        marginTop: '50px'
    },
    modal: {
        position: 'absolute',
        top: '10%',
        left: '10%',
        overflow: 'scroll',
        height: '100%',
        display: 'block',
    },
    editor: {
        position: "absolute" as "absolute",
        right: "20pt",
        bottom: "20pt",
        zIndex: 1000
    },
    errorText: {
        color: '#b90000'
    }
}

function filterMarker(marker: I.ExtendedMarker, filter: any, allowed: number[]) {
    if (filter.found === "found" && !marker.found) return false;
    else if (filter.found === "non-found" && marker.found) return false;
    if (!allowed.includes(marker.category)) return false;

    if (!filter.showDLC && marker.isDLC) {
        return false;
    }
    if (manager.search && !marker.name.toLowerCase().includes(manager.search.toLowerCase())) {
        return false;
    }
    return true;
}
//interface IProps {
//}

interface IForm {
    name: string,
    description: string,
    category: number,
    private: boolean,
    isDLC?: boolean
}
interface IState {
    allowedCategories: number[]
    minZoom: number,
    maxZoom: number,
    crs: L.CRS | null,
    editing: number[],
    ready: boolean,
    markers: I.ExtendedMarker[],
    showed: boolean,
    search: string,
    filter: {
        found: string,
        showDLC: boolean,
    }
    newMarkerStartingPosition: any,
    newMarkerPosition: any,
    form: IForm,
    categories: I.Category[],
    addingError: string,
    openModal: boolean,
    showNewTooltip: boolean,
    addSnackbar: boolean,
    addMarkerText: string,
    showFinds: boolean
}
export default class MainPage extends Component<any, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            allowedCategories: [],
            minZoom: 0,
            addSnackbar: false,
            maxZoom: 0,
            crs: null,
            addingError:'',
            search: '',
            editing: [],
            ready: false,
            markers: [],
            showed: false,
            showNewTooltip: true,
            addMarkerText: '',
            filter: {
                found: 'all',
                showDLC: true
            },
            newMarkerStartingPosition: null,
            newMarkerPosition: null,
            form: {
                name: '',
                description: '',
                category: 0,
                private: false,
            },
            //votes: [],
            categories: [],
            openModal: false,
            showFinds: true,
        }
    }
    toggleFinds = () => {
        this.setState({showFinds: !this.state.showFinds});
    }
    updatePosition = (e: any) => {
        if (e.target._latlng) this.setState({ newMarkerPosition: e.target._latlng })
    }
    updatePositionV2 = (markerId: number) => {
        return (e: any) => {
            const { markers } = this.state;
            const i = markers.map((marker: any) => marker.id).indexOf(markerId);
            const marker = markers[i];
            marker.position = [e.target._latlng.lat, e.target._latlng.lng];
            markers[i] = marker;
            this.setState({ markers });
        }
    }
    handleChange = (e: any) => {
        const { form }: any = this.state;
        form[e.target.name] = e.target.value;
        this.setState({ form });
    }
    handleDLC = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { form } = this.state;
        form.isDLC = event.target.checked;
        this.setState({ form });
    };
    handlePrivate = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { form } = this.state;
        form.private = event.target.checked;
        this.setState({ form });
    };
    handleChangeFilter = (e: any) => {
        const { filter }: any = this.state;
        filter[e.target.name] = e.target.value;
        this.setState({ filter }, () => console.log(this.state.filter));
    }
    toggleModal(isOpen: boolean) {
        this.setState({ openModal: isOpen }, () => {
            if (isOpen) {
                this.setState({ showNewTooltip: false })
            } else {
                this.setState({addingError:''});
            }
        });
    }
    toggleDrag = (markerId: number, state?: boolean) => {
        const { editing } = this.state;
        if (editing.includes(markerId) && !state) {
            return this.setState({ editing: editing.filter((id: number) => id !== markerId) });
        } else if (editing.includes(markerId)) {
            return;
        }
        editing.push(markerId);
        return this.setState({ editing });
    }
    submitMarker = () => {
        const newMarker = {
            ...this.state.form,
            position: [this.state.newMarkerPosition.lat, this.state.newMarkerPosition.lng]
        };
        api.markers.add(newMarker, () => {
            this.loadMarkers();
            this.setState({
                newMarkerStartingPosition: null, newMarkerPosition: null, openModal: false, form: {
                    name: '',
                    description: '',
                    category: 0,
                    private: false
                },
                addingError: '',
                addMarkerText: this.state.form.private ? 'Your private place has been added' : 'Your place has been added to review',
                addSnackbar: true,
            })
        }, () => {
            this.setState({addingError: 'Something went wrong! Try again in a few minutes'});
        });
    }
    openPopup = (markerId: number) => (marker: any) => {
        if (!this.state.showed && this.props.match && this.props.match.params.marker && marker && marker.leafletElement && markerId === Number(this.props.match.params.marker)) {
            marker.leafletElement.openPopup();
            const latLng = marker.leafletElement.getLatLng();
            const mapElement: any = this.refs.map;
            const map = mapElement.leafletElement;
            setTimeout(() => {
                map.setView(latLng, 5);
            }, 350)
            this.setState({ showed: true });
        }
    }
    addMarker = () => {
        if (this.state.newMarkerStartingPosition) return this.toggleModal(true);
        const mapElement: any = this.refs.map;
        const map = mapElement.leafletElement;
        this.setState({ newMarkerStartingPosition: map.getCenter(), newMarkerPosition: map.getCenter() });
    }
    loadMarkers = () => {
        api.markers.get({}, (res: I.ExtendedMarker[]) => {
            const markers = res.map(mark => typeof mark.position === "string" ? ({ ...mark, position: JSON.parse(mark.position) }) : mark);
            //setMarkers
            this.setState({ markers }, () => {
                manager.setMarkers(markers);
            });
        });
    }
    closeSnackbarAdd = () => {
        this.setState({addSnackbar: false});
    }
    componentDidMount() {
        manager.onSearchChange(() => {
            this.setState({ search: manager.search });
        })
        manager.toggle(true);
        this.loadMarkers();
        manager.onChange(() => {
            this.setState({ allowedCategories: manager.filter.categories, filter: { ...this.state.filter, showDLC: !!manager.filter.showDLC } });
        });
        api.categories.get((categories: I.Category[]) => {
            this.setState({ categories, allowedCategories: categories.map(category => category.id) }, () => {
                manager.updateFilter('categories', this.state.allowedCategories);
            });
        })
        const mapExtent = [0.00000000, -8192.00000000, 8192.00000000, 0.00000000];
        const mapMinZoom = 1;
        const mapMaxZoom = 5;
        const mapMaxResolution = 1.00000000;
        const mapMinResolution = Math.pow(2, mapMaxZoom) * mapMaxResolution;
        const crs = L.CRS.Simple;
        crs.scale = zoom => Math.pow(2, zoom) / mapMinResolution;
        crs.zoom = scale => Math.log(scale * mapMinResolution) / Math.LN2;

        const bounds = L.latLngBounds([crs.unproject(L.point(mapExtent[2], mapExtent[3])), crs.unproject(L.point(mapExtent[0], mapExtent[1]))]);

        this.setState({ minZoom: mapMinZoom, maxZoom: mapMaxZoom, crs: crs, ready: true }, () => {
            const map: any = this.refs.map;
            const mapElement = map.leafletElement;
            manager.map = mapElement;
            mapElement.fitBounds(bounds);
            mapElement.setMaxBounds([[mapExtent[3]+1000, mapExtent[2]], [mapExtent[1], mapExtent[0]]]);
            mapElement.fitWorld().zoomIn();
        });
    }


    isValid = () => {
        const { form } = this.state;
        return form.name && form.description && form.category > 1;
    }

    onDragstart = () => {
        this.setState({ showNewTooltip: false })
    }

    render() {
        const { markers, categories, filter, allowedCategories } = this.state;
        //const styles: { position: "absolute", right: string, bottom: string, zIndex: number } = { "position": "absolute", right: "20pt", bottom: "20pt", zIndex: 1000 }
        if (!this.state.ready || !this.state.crs) return <div></div>
        return (

            <Map ref='map' center={undefined} maxZoom={this.state.maxZoom} /*zoom={3}*/ minZoom={this.state.minZoom} crs={this.state.crs} >
                <TileLayer
                    url={`/maps/{z}/{x}/{y}.jpg`}
                    noWrap={true}
                    attribution="&copy; <a href='https://lexogrine.com'>Lexogrine</a>"
                />
                {
                    markers.filter(marker => filterMarker(marker, filter, allowedCategories))
                        .map(mark => <Markers
                            categories={categories}
                            key={mark.id}
                            onDrag={this.updatePositionV2}
                            draggable={this.state.editing.includes(mark.id)}
                            marker={mark}
                            cxt={this.props.cxt}
                            toggleDrag={this.toggleDrag}
                            openPopup={this.openPopup}
                            color={categories.find(category => category.id === mark.category)?.color || null}
                            loadMarkers={this.loadMarkers}
                        />)
                }
                {
                    this.state.newMarkerStartingPosition ? <Marker
                        icon={Icons.redIcon}
                        position={this.state.newMarkerStartingPosition}
                        draggable
                        onDragstart={this.onDragstart}
                        onDragend={this.updatePosition}
                        onclick={() => this.toggleModal(true)}
                    >
                        {this.state.showNewTooltip ? <LeafTooltip direction="bottom" permanent>Move your marker and click it to edit</LeafTooltip> : null}
                    </Marker> : null
                }

                {
                    this.props.cxt && this.props.cxt.user ? <Fab variant="extended" id="editor" onClick={this.addMarker}>
                        {this.state.newMarkerStartingPosition ? <Edit /> : <AddIcon />}
                        {this.state.newMarkerStartingPosition ? "Edit Your Place" : "Add New Place"}
                    </Fab> : null
                }
                <Paper className={`filter-box ${this.state.showFinds ? 'show-finds':'hide-finds'}`} id="founds-box">
                    <FormControl>
                        <FormLabel className="finds-title">My finds <div className="toggle-finds" onClick={this.toggleFinds}>{this.state.showFinds ? '-' : '+'}</div></FormLabel>
                        <RadioGroup
                            aria-label="My finds"
                            name="found"
                            value={this.state.filter.found}
                            onChange={this.handleChangeFilter}
                            className="show-finds"
                            id="my-finds"
                        >
                            <FormControlLabel value="all" control={<Radio />} label="All" className="finds-label" />
                            <FormControlLabel value="found" control={<Radio />} label="Found" className="finds-label" />
                            <FormControlLabel value="non-found" control={<Radio />} label="Not found" className="finds-label" />
                        </RadioGroup>
                    </FormControl>
                </Paper>
                <Modal
                    aria-labelledby="edit-marker-modal"
                    open={this.state.openModal}
                    onClose={() => this.toggleModal(false)}
                    className={'modal-overall'}
                >
                    <Paper style={styless.root} className="modal-marker-container">
                        <Typography variant="h5" component="h3" id="marker-title">
                            Add new place
                        </Typography>
                        <div id="position-marker">
                            {`${this.state.newMarkerPosition && this.state.newMarkerPosition.lat && Number(this.state.newMarkerPosition.lat).toFixed(0)}, ${this.state.newMarkerPosition && Number(this.state.newMarkerPosition.lng).toFixed(0)}`}
                        </div>
                        <div className="form-container">
                            { this.state.addingError ? <div style={styless.errorText}>{this.state.addingError}</div>: null}
                            <TextField
                                label="Name"
                                name='name'
                                value={this.state.form.name}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    classes: {
                                        notchedOutline: `input-fieldset ${this.state.form.name ? '' : 'empty'}`,
                                        input: 'input-marker'
                                    }
                                }}
                            />
                            <TextField
                                label="Description"
                                name='description'
                                value={this.state.form.description}
                                onChange={this.handleChange}
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    classes: {
                                        notchedOutline: `input-fieldset ${this.state.form.description ? '' : 'empty'}`,
                                        input: 'input-marker'
                                    }
                                }}
                            />
                            <TextField
                                select
                                label="Category"
                                value={this.state.form.category}
                                onChange={this.handleChange}
                                name='category'
                                margin="normal"
                                variant="outlined"
                                InputProps={{
                                    classes: {
                                        notchedOutline: `input-fieldset ${this.state.form.category ? '' : 'empty'}`,
                                        input: 'input-marker'
                                    }
                                }}
                            >
                                {this.state.categories.filter(category => !!category.parent).map(category => <MenuItem value={category.id} key={category.id} >{category.name}</MenuItem>)}
                            </TextField>
                            <FormControlLabel
                                control={<Checkbox
                                    checked={this.state.form.isDLC}
                                    onChange={this.handleDLC}
                                    value="isDLC"
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                />}
                                label="DLC"
                            />
                            <FormControlLabel
                                control={<Switch
                                    checked={this.state.form.private}
                                    onChange={this.handlePrivate}
                                    value="private"
                                    inputProps={{
                                        'aria-label': 'primary checkbox',
                                    }}
                                    classes={{
                                        checked: 'switch-checked-dot'
                                    }}
                                />}
                                label="Keep this marker secret"
                            />
                            <div className={"button-container"}>
                                <Button variant="outlined" onClick={this.submitMarker} style={this.isValid() ? styless.button : styless.buttonDisabled} disabled={!this.isValid()}>
                                    Submit
                                </Button>
                            </div>
                        </div>
                    </Paper>
                </Modal>
                    <Snackbar
                autoHideDuration={4000}
                        open={this.state.addSnackbar}
                        onClose={this.closeSnackbarAdd}
                    >
                        <Alert type="success">
                            {this.state.addMarkerText}
		                </Alert>
                    </Snackbar>
            </Map>
        )
    }
}
